import styles from './Admin.module.scss'
import NavBar from '../../components/NavBar';
import { useEffect, useState } from 'react';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import AddUser from './AddUser';
import AddBrand from './AddBrand';
import AddDesk from './AddDesk';
import BrandDataGrid from './BrandDataGrid';
import { useTranslation } from 'react-i18next';
import UsersDataGrid from './UsersDataGrid';
import { Navigate } from 'react-router-dom';

export default function Admin({ handleClick, setSnackType, setSnackMessage,isSmall , setSmall }) {
    const {t} = useTranslation()
    const [roles, setRoles] = useState([])
    const [brands, setBrands] = useState([])
    const [gridBrands , setGridBrands] = useState([])
    useEffect(() => {
        const FetchData = async () => {
            try {
                const rolesData = await axios.get('https://www.leadhubcrm.pro:8081/api/roles', {
                    headers: {
                        'Authorization': 'Bearer ' + secureLocalStorage.getItem('token')
                    }
                })
                const brandsData = await axios.get('https://www.leadhubcrm.pro:8081/api/brands', {
                    headers: {
                        'Authorization': 'Bearer ' + secureLocalStorage.getItem('token')
                    }
                })
                if (brandsData) {
                    setGridBrands(brandsData.data)
                    const arr = []
                    brandsData.data.forEach(el => {
                        arr.push({ value: el.Id, label: el.Title })
                    })
                    setBrands(arr)
                }
                if (rolesData) {
                    setRoles(rolesData.data.roles)
                }
            } catch (e) {
                console.log(e)
            }
        }
        FetchData()
    }, [])

    const CreateBrand = async (title, platformName, platformDomain) => {
        if (!title) {
            handleClick()
            setSnackType('error')
            setSnackMessage(t("AdminAddBrandSnackBarBrandError"))
            return
        }
        if (!platformName) {
            handleClick()
            setSnackType('error')
            setSnackMessage(t("AdminAddBrandSnackBarPlatformError"))
            return
        }
        if (!platformDomain) {
            handleClick()
            setSnackType('error')
            setSnackMessage(t("AdminAddBrandSnackBarDomainError"))
            return
        }
        try {
            const { data } = await axios.post('https://www.leadhubcrm.pro:8081/api/brands', {
                title,
                platform: platformName,
                domain: platformDomain,
                EndPoint:platformDomain,
            },
                {
                    headers: {
                        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                    },
                })
            if (data) {
                const brandsData = await axios.get('https://www.leadhubcrm.pro:8081/api/brands', {
                    headers: {
                        'Authorization': 'Bearer ' + secureLocalStorage.getItem('token')
                    }
                })
                if (brandsData) {
                    setGridBrands(brandsData.data)
                    const arr = []
                    brandsData.data.forEach(el => {
                        arr.push({ value: el.Id, label: el.Title })
                    })
                    setBrands(arr)
                }
                handleClick();
                setSnackMessage(t('AdminAddBrandSnackBarSuccess'));
                setSnackType("success");
            }
        } catch (e) {
            handleClick();
            setSnackMessage(e.response.data.message);
            setSnackType("error");
        }
    }
    
    return (
        secureLocalStorage.getItem('user')?.RoleId==5?
        <Navigate to={'/affiliate'}/>
        :
        <div className={styles.admin}>
            <NavBar
                isSmall={isSmall}
                setSmall={setSmall}
            />
            <div className={styles.content} style={isSmall?{marginLeft:'7.5%'}:{marginLeft:'19.5%'}}>
                <h1>{t("Administration")}</h1>
                <div className={styles.adds}>
                    <AddUser
                        t={t}
                        handleClick={handleClick}
                        roles={roles}
                        brands={brands}
                        setSnackType={setSnackType}
                        setSnackMessage={setSnackMessage}
                    />
                    <AddBrand 
                        t={t}
                        CreateBrand={CreateBrand}
                    />
                    {/* <AddDesk 
                        t={t}
                        handleClick={handleClick}
                        setSnackType={setSnackType}
                        setSnackMessage={setSnackMessage}                    
                        brands={brands}
                    /> */}
                    <BrandDataGrid
                        t={t}
                        handleClick={handleClick}
                        setSnackType={setSnackType}
                        setSnackMessage={setSnackMessage}
                        brands={gridBrands}
                        setGridBrands={setGridBrands}
                        setBrands={setBrands}
                    />
                </div>
                <div className={styles.usersGrid}>
                    <UsersDataGrid
                        t={t}
                        roles={roles}
                        handleClick={handleClick}
                        setSnackType={setSnackType}
                        setSnackMessage={setSnackMessage}
                        brands={brands}
                        setGridBrands={setGridBrands}
                        setBrands={setBrands}
                    />
                </div>
            </div>
        </div>
    )
}