import axios from "axios";
import { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import styles from './Admin.module.scss'
import Select from 'react-select';


export default function AddUser ({handleClick,setSnackType,setSnackMessage,roles,brands,t}){
    const [password , setPassword] = useState('');
    const [role ,setRole] = useState()
    const [login ,setLogin] = useState()
    const [desk ,setDesk] = useState()
    const [brand ,setBrand] = useState()
    const [desks, setDesks] = useState([])

    useEffect(() => {
        if (brand) {
            setDesk(null)
            const fetchBrand = async () => {
                try {
                    const { data } = await axios.get(`https://www.leadhubcrm.pro:8081/api/desks/brand/${brand}`, {
                        headers: {
                            'Authorization': 'Bearer ' + secureLocalStorage.getItem('token')
                        }
                    })
                    if (data) {
                        const arr = []
                        data.forEach(el => {
                            arr.push({ value: el.Id, label: el.Title })
                        })
                        setDesks(arr)
                    }
                } catch (e) {
                    console.log(e)
                }
            }
            fetchBrand()
        }
    }, [brand])
    function generateRandomPassword(length) {
        const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let password1 = "";

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            password1 += charset.charAt(randomIndex);
        }
        setPassword(password1)
    }
    const Create = async () =>{
        if(!login){
            handleClick()
            setSnackType('error')
            setSnackMessage(t("AdminAddUserSnackBarLoginError"))
            return
        }
        if(!password){
            handleClick()
            setSnackType('error')
            setSnackMessage(t("AdminAddUserSnackBarPasswordError"))
            return
        }
        if(!brand){
            handleClick()
            setSnackType('error')
            setSnackMessage(t("AdminAddUserSnackBarBrandError"))
            return
        }
        if(!desk){
            handleClick()
            setSnackType('error')
            setSnackMessage(t("AdminAddUserSnackBarDeskError"))
            return
        }
        if(!role){
            handleClick()
            setSnackType('error')
            setSnackMessage(t("AdminAddUserSnackBarRoleError"))
            return
        }
        try{
            const {data} = await axios.post('https://www.leadhubcrm.pro:8081/api/register',{
                login,
                password,
                roleId: role,
                deskId:desk.value,
                brandId:brand
              },
              {
                headers: {
                  Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                },
              })
              if(data){
                handleClick();
                setSnackMessage('Пользователь создан успешно!');
                setSnackType("success");
              }
        }catch(e){
            handleClick();
            setSnackMessage(e.response.data.message);
            setSnackType("error");
        }
    }
    return(
        
        <div className={styles.add}>
        <h3>{t('AdminAddUser')}</h3>
        <div className={styles.addContent}>
            <div className={styles.leftContent}>
                <span>
                <div className={styles.input}>
                    <label>Login</label>
                    <input onChange={(e)=>setLogin(e.target.value)}placeholder='Enter login'/>
                </div>
                <div className={styles.roleSelector}>
                    <label style={{marginBottom:'4px',fontWeight:'500'}}>{t('AdminRole')}</label>
                    <Select options={roles} onChange={(e)=>setRole(e.value)} className={styles.roleSelect} placeholder={t('AdminRoleSelect')}></Select>
                </div>
                <div className={styles.passGenerator}>
                    <span>{t('AdminPassword')}: {password}</span>
                    
                </div>
                </span>
                <div className={styles.passGenerator}>

                <button onClick={()=>generateRandomPassword(12)}>{t('AdminPasswordGenerate')}</button>
                </div>
            </div>
            <div className={styles.rightContent}>
                <span>
                    <div className={styles.brandSelector}>
                        <label style={{marginBottom:'4px',fontWeight:'500'}}>{t('AdminBrand')}</label>
                        <Select options={brands} onChange={(e)=>setBrand(e.value)} className={styles.roleSelect} placeholder={t('AdminBrandSelect')}></Select>
                    </div>
                    <div className={styles.deskSelector}>
                        <label style={{marginBottom:'4px',fontWeight:'500'}}>{t('AdminDesk')}</label>
                        <Select options={desks} value={desk} onChange={(e)=>setDesk({value : e.value ,label:e.label})} className={styles.deskSelect} placeholder={t('AdminDeskSelect')}></Select>
                    </div>
                </span>
                <div className={styles.createButton}>
                    <button onClick={Create} className={styles.create}>{t('AdminCreate')}</button>
                </div>
            </div>
        </div>

    </div>
    )
}