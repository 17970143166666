import styles from './Brands.module.scss'
import NavBar from '../../components/NavBar';

import secureLocalStorage from 'react-secure-storage';
import { Navigate } from 'react-router-dom';
export default function Brands(){
    return(
        secureLocalStorage.getItem('user')?.RoleId==5?
        <Navigate to={'/affiliate'}/>
        :
        <div className={styles.brands}>
            <NavBar/>
        </div>
    )
}