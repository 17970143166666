import { useState } from "react"
import Select from 'react-select';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import BrandsFields from "./BrandsFields";
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import Countries from '../../resources/countries.json';
export default function FlowsFields({ countries, el, DelField, AddBrand, DelBrand, updateBrandInField, brands, updateCountry }) {
    const [brandVisible , setBrandVisible] = useState(false)
    const [currentCountry,setCurrentCountry]=useState(el.country); 
    const current = el.brands.reduce((total, brand) => {
        if (typeof brand.current === 'number' && brand.brand_status) {
            return total + +brand.current;
        }
        return total;
    }, 0)
    const cap = el.brands.reduce((total, brand) => {
        if (typeof brand.cap === 'number') {
            return total + +brand.cap;
        }
        return total;
    }, 0)
    console.log(el)
    return (
        <>
            <span style={{ width: '100%', display: 'flex', alignItems: 'center' , justifyContent:'start',marginBottom: '34px',marginTop: '14px' }}>
                <KeyboardArrowRightRoundedIcon sx={!brandVisible?{transition:'all 0.5s ease',marginRight:'10px',marginTop:'19px'}:{transform:'rotate(90deg)',transition:'all 0.5s ease',marginRight:'10px',marginTop:'19px'}} onClick={()=> setBrandVisible(!brandVisible)}/>
                <div style={{ width: '60%', display:'flex',flexDirection:"row",alignItems:"center",justifycontent:"start" }}>
                    <div style={{width:"100%"}}>
                        <label style={{ marginBottom: '4px', fontWeight: '500' }}>{'Страна'}</label>
                        <Select value={{ value: el.country, label: el.country }} options={countries} onChange={(e) => { updateCountry(el.field_id, e.value);setCurrentCountry(e.value) }} styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: '30px',
                                backgroundColor: 'rgb(18,26,33)',
                                overflow: 'hidden',
                                color: 'rgba(231, 227, 252, 0.87)',
                                width: '70%'
                            }),
                            singleValue: (baseStyles, state) => ({
                                ...baseStyles,
                                fontWeight: '500',
                                color: 'rgba(231, 227, 252, 0.87)'
                            }),
                            option: (baseStyles, state) => ({
                                ...baseStyles,
                                backgroundColor: 'rgb(18,26,33)',
                                color: 'rgba(231, 227, 252, 0.87)'
                            }),
                            input: (baseStyles, state) => ({
                                ...baseStyles,
                                color: 'rgba(231, 227, 252, 0.87)'
                            }),
                            placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                color: 'rgba(231, 227, 252, 0.87)'
                            }),
                            menuList: (baseStyles, state) => ({
                                ...baseStyles,
                                backgroundColor: 'rgb(18,26,33)',
                                color: 'rgba(231, 227, 252, 0.87)'

                            }),
                        }} placeholder={'Страна'}></Select>
                    </div>
                    <div style={{marginLeft:"-100px",marginTop:"19px",display:'flex', alignItems:"center",gap:"5px"}}>
                    <img src={Countries.find((obj) => obj.name.official == el.country || obj.name.common == el.country || obj.altSpellings.includes(el.country))?.flags?.png} alt={Countries.find((obj) => obj.name.common == el.country)?.flags?.alt} height={11} className="me-2" width={16} />
                    <span>{Countries.find((obj) => obj.name.official == el.country || obj.name.common == el.country || obj.altSpellings.includes(el.country))?.altSpellings[0]}</span>
                    </div>
                </div>
                <div style={{ width: '30%', display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: "14px",gap:'10px' }}>
                    <div style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                        <span style={current >= cap ? { fontWeight: '500' } : { color: 'red', fontWeight: '500' }}>{current}</span>/<span style={{ fontWeight: '500' }}>{cap}</span>
                        <AddBoxRoundedIcon onClick={() =>{ AddBrand(el.field_id); setBrandVisible(true)}} sx={{ color: 'rgba(231, 227, 252, 0.87)', marginLeft: '10px', cursor: 'pointer' }} />
                    </div>
                    <DeleteForeverRoundedIcon onClick={() => DelField(el.field_id)} sx={{ color: 'rgba(231, 227, 252, 0.87)', marginLeft: '10px', cursor: 'pointer' }} />
                </div>
            </span>

            <div style={brandVisible?{ width: '100%', display: 'flex', justifyContent: 'right', flexDirection: 'column', alignItems: 'end', gap: '8px',transition:'all 0.5s ease' }:{display: 'none',height:'0px' }}>
                {
                    el.brands.length > 0 && (
                        el.brands.map(elem =>
                            <BrandsFields brands={brands.filter((element) => !el.brands.map(brand => brand.brand_name).flat().includes(element.label))} DelBrand={DelBrand} elem={elem} key={elem.brand_id} el={el} updateBrandInField={updateBrandInField} />
                        )
                    )
                }
            </div>
        </>

    )
}

