import { useState } from 'react';
import styles from './Login.module.scss'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import logo from '../../imgs/logo.png'
import logo2 from '../../imgs/logo7.png'
import { Link, Navigate, useNavigate } from 'react-router-dom';
export default function Login(){
    const [isPassVis ,setIsPassVis] = useState(false)
    const [password , setPassword] = useState('')
    const [passwordError , setPasswordError] = useState('')
    const [login , setLogin] = useState('')
    const [loginError , setLoginError] = useState('')
    const [authError , setAuthError] = useState('')
    const navigate = useNavigate();
    const Check = async() =>{
        if(!login){
            setLoginError('Введите логин!')
        }
        if(!password){
            setPasswordError('Введите пароль!')
        }
        try{
            const {data} = await axios.post('https://www.leadhubcrm.pro:8081/api/login',{login , password})
            if(data){
                secureLocalStorage.setItem('user' , data.user)
                secureLocalStorage.setItem('token' , data.token)
                
                if(secureLocalStorage.getItem('currentPathname')){
                    if(data.user.RoleId == '777'){
                        navigate('/clients',{replace:true})
                    }
                    if(data.user.RoleId == '5'){
                        navigate('/affiliate',{replace:true})
                    }
                    else{
                        navigate('/clients',{replace:true})
                    }
                    // if(secureLocalStorage.getItem('currentPathname')!='/login'){
                    //     navigate('/',{replace:true})
                    // }
                    // else{
                    //     console.log(secureLocalStorage.getItem('currentPathname'))
                    //     return <Navigate to={secureLocalStorage.getItem('currentPathname')}/>
                    // }
                }
                else{
                    if(data.user.RoleId == '777'){
                        navigate('/clients',{replace:true})
                    }
                    if(data.user.RoleId == '5'){
                        navigate('/affiliate',{replace:true})
                    }
                    else{
                        navigate('/clients',{replace:true})
                    }
                }
            }
        }catch(e){
            setAuthError(e.response.data.message)
            console.log(e)
        }
    }
    return(
        <div className={styles.page}>
            <div className={styles.leftSide}>
                <div className={styles.leftContent}>
                    <h1>Добро пожаловать!</h1>
                    <img src={'https://minimals.cc/assets/illustrations/illustration_dashboard.png'} alt='login'/>
                </div>
            </div>
            <div className={styles.rightSide}>
                <div className={styles.rightContent}>
                    <div style={{display:'flex' , alignItems:'center' , justifyContent:'center', width:'100%',gap:'10px',marginBottom:'10px'}}>
                        <img src={logo2} style={{width:'200px'}}/>
                    </div>
                    {/* <h3>Войти в систему CRM</h3> */}
                    <span className={styles.loginDesc}>
                        <InfoRoundedIcon sx={{color:'#6EDFF5'}}/>
                        <p><Link to='/register' style={{color:'#6EDFF5'}}><b>Зарегистрироваться как аффилиат</b></Link></p>
                    </span>
                    <div className={styles.input}>
                        <label>Логин</label>
                        <input placeholder='Логин' type={'text'} value={login} onChange={(e)=> {setLogin(e.target.value);setLoginError('')}}/>
                        {
                            loginError?<p>{loginError}</p>:''
                        }
                    </div>
                    <div className={styles.input}>
                        <label>Пароль</label>
                        <input placeholder='Пароль' type={isPassVis?'text':'password'} value={password} onChange={(e)=> {setPassword(e.target.value);setPasswordError('')}}/>
                        {
                            isPassVis?
                            <span onClick={()=> setIsPassVis(false)}>
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" className="component-iconify MuiBox-root css-1t9pz9x iconify iconify--solar" width="24px" height="24px" viewBox="0 0 24 24">
                                    <path fill="#637381" d="M9.75 12a2.25 2.25 0 1 1 4.5 0a2.25 2.25 0 0 1-4.5 0" /><path fill="#637381" fillRule="evenodd" d="M2 12c0 1.64.425 2.191 1.275 3.296C4.972 17.5 7.818 20 12 20c4.182 0 7.028-2.5 8.725-4.704C21.575 14.192 22 13.639 22 12c0-1.64-.425-2.191-1.275-3.296C19.028 6.5 16.182 4 12 4C7.818 4 4.972 6.5 3.275 8.704C2.425 9.81 2 10.361 2 12m10-3.75a3.75 3.75 0 1 0 0 7.5a3.75 3.75 0 0 0 0-7.5" clipRule="evenodd" />
                                </svg>
                            </span>
                            :
                            <span onClick={()=> setIsPassVis(true)}>
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" className="component-iconify MuiBox-root css-1t9pz9x iconify iconify--solar" width="24px" height="24px" viewBox="0 0 24 24">
                                    <path fill="#637381" fillRule="evenodd" d="M1.606 6.08a1 1 0 0 1 1.313.526L2 7l.92-.394v-.001c0-.001 0 0 0 0l.003.009l.021.045c.02.042.051.108.094.194c.086.172.219.424.4.729a13.37 13.37 0 0 0 1.67 2.237a11.966 11.966 0 0 0 .59.592C7.18 11.8 9.251 13 12 13a8.706 8.706 0 0 0 3.22-.602c1.227-.483 2.254-1.21 3.096-1.998a13.053 13.053 0 0 0 2.733-3.725l.027-.058l.005-.011a1 1 0 0 1 1.838.788L22 7l.92.394l-.003.005l-.004.008l-.011.026l-.04.087a14.045 14.045 0 0 1-.741 1.348a15.368 15.368 0 0 1-1.711 2.256l.797.797a1 1 0 0 1-1.414 1.415l-.84-.84a11.81 11.81 0 0 1-1.897 1.256l.782 1.202a1 1 0 1 1-1.676 1.091l-.986-1.514c-.679.208-1.404.355-2.176.424V16.5a1 1 0 0 1-2 0v-1.544c-.775-.07-1.5-.217-2.177-.425l-.985 1.514a1 1 0 0 1-1.676-1.09l.782-1.203c-.7-.37-1.332-.8-1.897-1.257l-.84.84a1 1 0 0 1-1.414-1.414l.797-.797a15.406 15.406 0 0 1-1.87-2.519a13.457 13.457 0 0 1-.591-1.107a5.418 5.418 0 0 1-.033-.072l-.01-.021l-.002-.007l-.001-.002v-.001C1.08 7.395 1.08 7.394 2 7l-.919.395a1 1 0 0 1 .525-1.314" clipRule="evenodd" />
                                </svg>
                            </span> 
                        }
                        {
                            passwordError?<p>{passwordError}</p>:''
                        }
                    </div>
                    <button onClick={Check}>Войти</button>
                    {
                        authError?<p className={styles.authError} style={{width:'100%' , textAlign:'center',color:'red'}}>{authError}</p>:''
                    }
                    
                </div>
            </div>
        </div>
    )
}