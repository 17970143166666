import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import './App.scss';
import React , { useEffect, useState } from 'react';
import MyProjects from './pages/Projects/MyProjects';
import Login from './pages/Login/Login';
import AdminChoose from './pages/AdminChoose/AdminChoose';
import Desks from './pages/Desks/Desks';
import Shifts from './pages/Shifts/Shifts';
import Support from './pages/Support/Support';
import Brands from './pages/Brands/Brands';
import Tables from './pages/Tables/Tables';
import Admin from './pages/Admin/Admin';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import i18n from './i18n/config';
import secureLocalStorage from 'react-secure-storage';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Clients from './pages/Clients/Clients';
import AddClient from './pages/Clients/AddClient';
import Flows from './pages/Flows/Flows';
import Statuses from './pages/Statuses/Statuses';
import Register from './pages/Register/Register';
import Affiliate from './pages/Affiliate/Affiliate';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function App() {
  const location = useLocation();
  const [isSmall, setSmall] = useState(secureLocalStorage.getItem('collapse')?secureLocalStorage.getItem('collapse'):false);
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackType, setSnackType] = useState('success');
  const nav = useNavigate();
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    secureLocalStorage.setItem('currentPathname', location.pathname);
    
  }, [location.pathname]);
  useEffect(()=>{
    console.log(secureLocalStorage.getItem("user"));
    if(secureLocalStorage.getItem('token')){
      axios.post('https://www.leadhubcrm.pro:8081/api/verifytoken',{
        token:secureLocalStorage.getItem('token')
      }).then((result)=>{
        if(result.data.message=='invalid token'){
          secureLocalStorage.removeItem('token');
          nav('/login');
        }
      }).catch((err)=>{
          nav('/login');

      })
    }
    else{
    
          nav('/login');
    }
      
    setInterval(async ()=>{
      if(secureLocalStorage.getItem('token')){
      const result = await axios.post('https://www.leadhubcrm.pro:8081/api/verifytoken',{
        token:secureLocalStorage.getItem('token')
      }).then(()=>{
        if(result.data.message=='invalid token'){
          secureLocalStorage.removeItem('token');
          nav('/login');
        }
      }).catch((err)=>{

      })
      
    }
    },30000)
    if(secureLocalStorage.getItem('lang')){
      i18n.changeLanguage(secureLocalStorage.getItem('lang'));
    }
    else{
      i18n.changeLanguage("ru");
      secureLocalStorage.setItem("lang","ru");
    }
  },[])
  return (
    <div className="App">
      <Routes>
        {
          secureLocalStorage.getItem('user')?
          secureLocalStorage.getItem('user')?.RoleId==5?
          <Route exact path="/" element={<Navigate to="/affiliate" replace={true}/>}/>
          :
          <Route exact path="/" element={<Navigate to="/clients" replace={true}/>}/>
          :
          <Route exact path="/" element={<Navigate to="/login" replace={true}/>}/>
        }
        <Route path={'/settings'} element={<MyProjects />}></Route>
        <Route path={'/desks'} element={<Desks />}></Route>
        <Route path={'/shifts'} element={<Shifts />}></Route>
        <Route path={'/support'} element={<Support />}></Route>
        <Route path={'/brands'} element={<Brands />}></Route>
        <Route path={'/tables'} element={<Tables />}></Route>
        <Route path={'/affiliate'} element={<Affiliate handleClick={handleClick} 
            setSnackType={setSnackType} 
            setSnackMessage={setSnackMessage}
            isSmall={isSmall}
            setSmall={setSmall}  />}></Route>
        <Route path={'/statuses'} element={<Statuses 
                      handleClick={handleClick} 
                      setSnackType={setSnackType} 
                      setSnackMessage={setSnackMessage}
                      isSmall={isSmall}
                      setSmall={setSmall}
        />}></Route>
        <Route path={'/clients'} element={
          <Clients
            handleClick={handleClick} 
            setSnackType={setSnackType} 
            setSnackMessage={setSnackMessage}
            isSmall={isSmall}
            setSmall={setSmall} 
          />}>
        </Route>
        <Route path={'/flows'} element={
          <Flows
            handleClick={handleClick} 
            setSnackType={setSnackType} 
            setSnackMessage={setSnackMessage}
            isSmall={isSmall}
            setSmall={setSmall} 
          />}>
        </Route>
        <Route path={'/client/add'} element={
          <AddClient
            handleClick={handleClick} 
            setSnackType={setSnackType} 
            setSnackMessage={setSnackMessage}
            isSmall={isSmall}
            setSmall={setSmall} 
          />}>
        </Route>
        <Route path={'/admin'} element={
          <Admin 
            handleClick={handleClick} 
            setSnackType={setSnackType} 
            setSnackMessage={setSnackMessage}
            isSmall={isSmall}
            setSmall={setSmall}
          />}>
        </Route>
        <Route path={'/login'} element={<Login/>}></Route>
        <Route path={'/register'} element={<Register/>}></Route>
        <Route path={'/admin/choose'} element={<AdminChoose/>}></Route>
      </Routes>
      
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snackType} sx={{ width: '100%', fontFamily: '"Montserrat" , sans-serif' }}>
          {snackMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default App;
