import styles from './AdminChoose.module.scss'

export default function AdminChoose(){
    return(
        <div className={styles.page}>
            <div className={styles.content}>
                <div className={styles.user}></div>
                <div className={styles.admin}></div>
            </div>
        </div>
    )
}
