

import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&::before, &::after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&::before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&::after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

export default function BrandsFields({ brands, DelBrand, elem, el, updateBrandInField }) {
    const [brand, setBrand] = useState()
    const [cappaIsEdit, setIsEdit] = useState(false);
    const [percent, setPercent] = useState('')
    const [cap, setCap] = useState('')
    const [brandData, setBrandData] = useState({
        brand_id: elem.brand_id ? elem.brand_id : null,
        percent: elem.percent ? elem.percent : 0,
        cap: elem.cap ? elem.cap : 0,
        brand_name: elem.brand_name ? elem.brand_name : null,
        brand_status: elem.brand_status
    })
    useEffect(() => {
        if (brandData) {
            updateBrandInField(el.field_id, elem.brand_id, brandData)
        }
    }, [brandData, percent])
    useEffect(() => {
        if (brandData) {
            updateBrandInField(el.field_id, elem.brand_id, brandData)
        }
    }, [brandData, cap])
    console.log(elem)
    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Select isDisabled={!elem.brand_status} value={{ value: elem.id, label: elem.brand_name }} options={brands} onChange={(e) => { setBrandData(prev => ({ ...prev, id: e.value, brand_name: e.label })); setBrand({ value: e.value, label: e.label }) }} styles={{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '30px',
                    backgroundColor: 'rgb(18,26,33)',
                    overflow: 'hidden',
                    color:  elem.brand_status?'rgba(231, 227, 252, 0.87)':"rgba(231, 227, 252, 0.37)",
                    borderColor:  elem.brand_status?'rgba(231, 227, 252, 0.87)':"rgba(231, 227, 252, 0.37)",
                    width: '180px',
                    marginLeft:'35px',
                    marginTop:'15px'
                }),
                singleValue: (baseStyles, state) => ({
                    ...baseStyles,
                    fontWeight: '500',
                    color: elem.brand_status?'rgba(231, 227, 252, 0.87)':"rgba(231, 227, 252, 0.37)"
                }),
                option: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: 'rgb(18,26,33)',
                    color: elem.brand_status?'rgba(231, 227, 252, 0.87)':"rgba(231, 227, 252, 0.37)",
                }),
                input: (baseStyles, state) => ({
                    ...baseStyles,
                    color: elem.brand_status?'rgba(231, 227, 252, 0.87)':"rgba(231, 227, 252, 0.37)"
                }),
                placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                    color: elem.brand_status?'rgba(231, 227, 252, 0.87)':"rgba(231, 227, 252, 0.37)"
                }),
                menuList: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: 'rgb(18,26,33)',
                    color: elem.brand_status?'rgba(231, 227, 252, 0.87)':"rgba(231, 227, 252, 0.37)",

                }),
            }} placeholder={'Бренд'}>

            </Select>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' , width:'17%',marginTop:'15px' }}>
                {
                    !cappaIsEdit && (
                        <>
                            <div style={elem.brand_status?{}:{color:"#89898e" }}>
                                <span style={elem.current >= elem.cap?{}:{color:'red'}}>{elem.current?elem.current:0}</span>/<span>{elem.cap?elem.cap:0}</span>
                            </div>
                            <EditRoundedIcon onClick={elem.brand_status?() => setIsEdit(true): null} sx={elem.brand_status?{ cursor: 'pointer' }:{ cursor: 'pointer',color:"#89898e" }} />
                        </>
                    )
                }
                {
                    cappaIsEdit && (
                        <>
                            <div>
                                <input placeholder='Cap' onChange={(e) => { setBrandData(prev => ({ ...prev, cap: e.target.value })); setCap(e.target.value) }} style={{ background: 'none', padding: "10px", width: "calc(60px - 22px)", color: "rgba(231, 227, 252, 0.87)", fontFamily: '"Montserrat",sans-serif', border: '1px solid rgba(231, 227, 252, 0.87)', borderRadius: '6px' }} />
                            </div>
                            <CheckCircleOutlineRoundedIcon onClick={() => setIsEdit(false)} sx={{ cursor: 'pointer' }} />
                        </>
                    )
                }
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', flexDirection: 'column', width: "100px" }}>
                <label style={elem.brand_status?{ fontSize: '12px'}:{color:"#89898e", fontSize: '12px' }}>Процент</label>
                <input placeholder='Процент' disabled={!elem.brand_status} value={elem.percent} onChange={(e) => { setBrandData(prev => ({ ...prev, percent: e.target.value })); setPercent(e.target.value) }} style={{ 
                    background: 'none', 
                    padding: "10px", 
                    width: "calc(100px - 22px)", 
                    color: elem.brand_status?"rgba(231, 227, 252, 0.87)":"rgba(231, 227, 252, 0.37)", 
                    fontFamily: '"Montserrat",sans-serif', 
                    border: elem.brand_status?'1px solid rgba(231, 227, 252, 0.87)':'1px solid rgba(231, 227, 252, 0.37)', 
                    borderRadius: '6px' 
                    }} />
            </div>

            <FormControlLabel
                control={<Android12Switch sx={{marginTop:'15px'}}  checked={brandData.brand_status}  onChange={(e)=>  setBrandData(prev => ({ ...prev, brand_status: e.target.checked }))}/>}
            />
            <RemoveCircleRoundedIcon onClick={() => DelBrand(el.field_id, elem.brand_id)} sx={{ cursor: 'pointer',marginTop:'15px' }} />
        </div>
    )
}
