import {
    DataGrid,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useEffect, useState } from 'react';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Backdrop, Fade, Modal, FormControl, InputAdornment, OutlinedInput, Checkbox} from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import Select from 'react-select';
import Swal from 'sweetalert2';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import ManageSearchRoundedIcon from '@mui/icons-material/ManageSearchRounded';
import { Countries } from '../../countries';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import FlowsFields from './FlowsField';
function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <Pagination
            variant="outlined"
            shape="rounded"
            page={page + 1}
            count={pageCount}
            // @ts-expect-error
            renderItem={(props2) => <PaginationItem {...props2} sx={{ color: "#688CF2", borderColor: "#688CF2" }} disableRipple />}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
        />
    );
}
const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '60px',
    height: '100%',
    '& .ant-empty-img-1': {
        fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
        fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
        fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
        fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
        fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
        fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
}));
const style = {
    position: 'absolute',
    top: '50%',
    right: '0',
    transform: 'translate(-0%, -50%)',
    bgcolor: '#171E29',
    boxShadow: 24,
    p: 4,
    borderRadius: '12px',
    display: 'flex',
    gap: '15px',
    backgroundColor: '#171E29',
    width: "640px",
    color: 'rgba(231, 227, 252, 0.87)',
    minHeight: '300px',
    height:'calc(100vh - 40px)'
};
function CustomNoRowsOverlay() {
    return (
        <StyledGridOverlay>
            <svg
                style={{ flexShrink: 0 }}
                width="200"
                height="160"
                viewBox="0 0 184 152"
                aria-hidden
                focusable="false"
            >
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(24 31.67)">
                        <ellipse
                            className="ant-empty-img-5"
                            cx="67.797"
                            cy="106.89"
                            rx="67.797"
                            ry="12.668"
                        />
                        <path
                            className="ant-empty-img-1"
                            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                            className="ant-empty-img-2"
                            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                            className="ant-empty-img-3"
                            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                    </g>
                    <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                    />
                    <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                    </g>
                </g>
            </svg>
            <Box sx={{ mt: 1 }}>Empty set</Box>
        </StyledGridOverlay>
    );
}
function CustomToolbar({ search, setSearch, t, handleOpenAddModal }) {
    return (
        <div style={secureLocalStorage.getItem('user')?.RoleId == 777 ?
            { width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingTop: '20px' }
            : { width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'end', paddingTop: '20px' }
        }>
            {
                secureLocalStorage.getItem('user')?.RoleId == 777 ?
                    <button onClick={handleOpenAddModal} style={{ border: 'none', backgroundColor: '#04948C', color: '#fff', padding: '15px 20px', fontFamily: "'Montserrat' , sans-serif", borderRadius: "4px", fontWeight: '700', cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '10px', marginLeft: '20px' }} variant="outlined" color="secondary" size="small" >
                        <AddCircleRoundedIcon /> ДОБАВИТЬ
                    </button>
                    : ''
            }

            <div style={{ width: '250px', marginRight: '20px' }}>
                <label style={{ marginTop: '0', fontWeight: '500' }}>{t('ClientsGridSearch')}</label>
                <FormControl
                    color="warning" fullWidth sx={{
                        width: '100%',
                        '& .MuiTypography-root': {
                            color: '#fff',
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'lightgrey',
                                borderRadius: '8px'

                            },
                            '&:hover fieldset': {
                                borderColor: 'lightgrey',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#fff',
                            },
                        },
                    }}
                >
                    <OutlinedInput
                        type="text"
                        sx={{
                            color: "#fff", fontFamily: "'Montserrat',sans-serif", appearance: 'none', "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                display: "none",
                            },
                            "& input[type=text]": {
                                color: "#fff",
                                MozAppearance: "textfield",
                            },
                        }}
                        id="outlined-adornment-amount"
                        startAdornment={<InputAdornment position="start">
                            <ManageSearchRoundedIcon sx={{ color: '#688CF2' }} />
                        </InputAdornment>}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        min={0}
                    />
                </FormControl>
            </div>
        </div>
    );
}


export default function FlowsDataGrid({ handleClick, setSnackType, setSnackMessage, t }) {
    const [currentRow, setCurrentRow] = useState()
    const [openModal, setOpenModal] = useState(false);
    const [openAddModal, setOpenAddModal] = useState(false);
    const [affId,setAffId] = useState(0);
    const [search, setSearch] = useState('')
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [EndPoint, setEndPoint] = useState('')
    const [affiliate_id, setAffiliate_id] = useState()
    const [flows, setFlows] = useState([])
    const [countries, setCountries] = useState([])
    const [fields, setFields] = useState([])
    const [brands, setBrands] = useState([])
    const [rowId, setRowId] = useState()
    const [filteredFlows, setFilteredFlows] = useState([]);
    const [affiliates, setAffiliates] = useState();
    const handleOpenAddModal = (row) => {
        setOpenAddModal(true)
    }
    const handleCloseAddModal = () => {
        setOpenAddModal(false);
        setDescription('')
        setTitle('')
        setEndPoint('')
        setAffiliate_id(null)
    };
    const handleOpenModal = (row) => {
        setOpenModal(true)
        setFields(row.fields)
        setRowId(row.id)
        setAffId(row.affiliate_id)
    };
    const handleCloseModal = () => {
        setOpenModal(false);
        setCurrentRow(null)
    };
    useEffect(() => {
        if (currentRow) {

        }
        if (!currentRow) {

        }
    }, [currentRow])
    const AddFlow = async () => {
        if (!affiliate_id) {
            handleClick();
            setSnackMessage("Выберите аффилиата потока");
            setSnackType("error");
            return
        }
        if (!title) {
            handleClick();
            setSnackMessage("Введите название потока");
            setSnackType("error");
            return
        }
        if (!description) {
            handleClick();
            setSnackMessage("Введите описание потока");
            setSnackType("error");
            return
        }
        if (!EndPoint) {
            handleClick();
            setSnackMessage("Введите endpoint потока");
            setSnackType("error");
            return
        }
        try {
            const newAff = await axios.post('https://www.leadhubcrm.pro:8081/api/flows', {
                title,
                description,
                affiliate_id,
                EndPoint
            },
            {
                headers: {
                    Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                },
            })
            if(newAff.data){
                const { data } = await axios.get('https://www.leadhubcrm.pro:8081/api/flows', {
                    headers: {
                        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                    },
                })
                if (data) {
                    setFlows(data.flows)
                    setFilteredFlows(data.flows)
                    handleCloseAddModal();
                    handleClick();
                    setSnackMessage("Поток добавлен успешно");
                    setSnackType("success");
                }
            }
        } catch (e) {
            handleClick();
            setSnackMessage(e.response.data.message);
            setSnackType("error");
        }
    }
    useEffect(() => {
        const fetchFlows = async () => {
            try {
                const { data } = await axios.get('https://www.leadhubcrm.pro:8081/api/flows', {
                    headers: {
                        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                    },
                })
                const brands = await axios.get('https://www.leadhubcrm.pro:8081/api/brands', {
                    headers: {
                        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                    },
                })
                const aff = await axios.get('https://www.leadhubcrm.pro:8081/api/affiliates', {
                    headers: {
                        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                    },
                })
                if (aff) {
                    let temp = []
                    aff.data.forEach(el => {
                        temp.push({ value: el.Id, label: el.Login })
                    })
                    setAffiliates(temp)
                }
                if (brands.data) {
                    let temp = []
                    brands.data.forEach(el => {
                        temp.push({ value: el.Id, label: el.Title })
                    })
                    setBrands(temp)
                }
                if (data) {
                    setFlows(data.flows)
                    setFilteredFlows(data.flows)
                }
            } catch (e) {
                handleClick();
                setSnackMessage(e.response.data.message);
                setSnackType("error");
            }
        }
        fetchFlows()
        let temp = []
        Countries.forEach(el =>
            temp.push({ value: el.name, label: el.name })
        )
        temp.push({ value: "All", label: "All" })
        if (temp.length > 0) {
            setCountries(temp)
        }
    }, [])

    const filterTable = (searchValue) => {
        if (searchValue === 'trer' || "") {
            return flows;
        }
        return flows.filter((article) =>
            article.title.toString().toLowerCase().includes(searchValue.toLowerCase())
            // article.broker.toString().toLowerCase().includes(searchValue.toLowerCase()) || article.crm.toString().toLowerCase().includes(searchValue.toLowerCase())
        );
    };
    useEffect(() => {
        setFilteredFlows(filterTable(search));
    }, [search])

    const columns = [
        {
            field: 'asdafaw',
            headerName: '',
            width: 20,
            editable: false,
            sortable: false,
        },
        {
            field: 'id',
            headerName: 'ID',
            width: 80,
            editable: false,
        },
        {
            field: 'affiliate_id',
            headerName: 'Affiliate_id',
            width: 80,
            editable: false,
        },
        {
            field: 'title',
            headerName: '',
            width: 210,
            editable: false,
            renderCell: (params) => {
                const currentRow = params.row;

                return (
                    <span style={{ fontWeight: '500', textDecoration: 'none', color: '#10706B' }} variant="outlined" color="secondary" size="small" >{currentRow.title}</span>
                );
            },
        },
        {
            field: 'description',
            headerName: 'ОПИСАНИЕ',
            width: 190,
            editable: false,
        },
        {
            field: 'kappa',
            headerName: 'КАППА',
            width: 210,
            editable: false,
            renderCell: (params) => {
                const currentRow = params.row;
                const totalCap = currentRow.fields.reduce((total, field) => {
                    return total + field.brands.reduce((sum, brand) => sum + (brand.cap || 0), 0);
                }, 0);
                const totalCurrent = currentRow.fields.reduce((total, field) => {
                    return total + field.brands.reduce((sum, brand) => {
                        if(brand.brand_status){
                            return sum + (brand.current || 0)
                        }
                        return sum;
                    }, 0);
                }, 0);

                return (
                    <div style={{ display: 'flex', gap: '4px', fontWeight: 'bold' }}>
                        <span style={totalCurrent > totalCap ? {} : { color: 'red' }}>{totalCurrent}</span>/<span>{totalCap}</span>
                    </div>
                );
            },
        },
        {
            field: 'action',
            headerName: '',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 310,
            editable: false,
            type: 'number',
            renderCell: (params) => {
                const currentRow = params.row;

                return (
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <button onClick={() => handleOpenModal(currentRow)} style={{ border: 'none', background: 'none', color: '#fff', padding: '5px 10px', fontFamily: "'Montserrat' , sans-serif", borderRadius: "4px", fontWeight: '700', cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '10px' }} variant="outlined" color="secondary" size="small" >
                            <EditRoundedIcon /> 
                        </button>
                        <button onClick={() => DeleteFlows(currentRow?.id)} style={{ border: 'none', background: 'none', color: '#fff', padding: '5px 10px', fontFamily: "'Montserrat' , sans-serif", borderRadius: "4px", fontWeight: '700', cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '10px' }} variant="outlined" color="secondary" size="small" ><DeleteForeverRoundedIcon /></button>
                    </div>
                );
            },
        },
    ];

    const DeleteFlows = async (id) => {
        Swal.fire({
            background: '#2B2D3A',
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            color: '#fff',
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: 'rgba(255, 48, 48, 0.16)',
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
            buttonsStyling: false,
            customClass: {
                title: '',
                text: 'text',
                cancelButton: 'Custom_Cancel_LightMode',
                confirmButton: 'Custom_Confirm_LightMode',
            }

        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`https://www.leadhubcrm.pro:8081/api/flows/${id}`, {
                        headers: {
                            Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                        },
                    })
                    const flows = await axios.get('https://www.leadhubcrm.pro:8081/api/flows', {
                        headers: {
                            'Authorization': 'Bearer ' + secureLocalStorage.getItem('token')
                        }
                    })
                    if (flows) {
                        setFlows(flows.data.flows)
                        setFilteredFlows(flows.data.flows)

                    }
                    Swal.fire({
                        title: "Deleted!",
                        text: "Flow has been deleted.",
                        icon: "success",
                        background: '#2B2D3A',
                        color: '#fff',
                        buttonsStyling: false,
                        customClass: {
                            confirmButton: 'Custom_Confirm_LightMode',
                        }

                    });
                } catch (e) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        background: '#2B2D3A',
                        color: '#fff',
                        buttonsStyling: false,
                        customClass: {
                            confirmButton: 'Custom_Confirm_LightMode',
                        }

                    });
                }
            }
        });
    }
    const AddField = () => {
        setFields(prev => [...prev, { field_id: Date.now(), country: '', brands: [] }])
    }
    const DelField = (id) => {
        setFields(prev => [...prev.filter(el => el.field_id !== id)])
    }
    const AddBrand = (id) => {
        setFields(prev => prev.map(field => {
            if (field.field_id === id) {
                // Создаём копию поля, чтобы не изменять оригинальный объект
                const updatedField = { ...field };
                // Также создаём копию массива brands, чтобы добавить в него новый элемент
                updatedField.brands = [...field.brands, { brand_id: Date.now() }];
                return updatedField;
            }
            return field;
        }));
    }
    const DelBrand = (field_id, brand_id) => {
        setFields(prev => prev.map(field => {
            if (field.field_id === field_id) {
                const updatedField = { ...field };
                updatedField.brands = [...field.brands.filter(el => el.brand_id !== brand_id)];
                return updatedField;
            }
            return field;
        }));
    }
    console.log(fields)
    function updateBrandInField(fieldId, brandId, newBrandData) {
        setFields((prevFields) => {
            return prevFields.map((field) => {
                // Находим поле, которое нужно обновить
                if (field.field_id === fieldId) {
                    // Создаем копию поля с обновленными брендами
                    const updatedField = {
                        ...field,
                        brands: field.brands.map((brand) => {
                            // Находим бренд, который нужно обновить
                            if (brand.brand_id === brandId) {
                                // Возвращаем обновленный бренд
                                return { ...brand, ...newBrandData };
                            }
                            // Возвращаем неизмененные бренды
                            return brand;
                        }),
                    };
                    return updatedField;
                }
                // Возвращаем неизмененные поля
                return field;
            });
        });
    }
    const updateCountry = (id, country) => {
        setFields((prevFields) => {
            return prevFields.map((field) => {
                // Находим поле, которое нужно обновить
                if (field.field_id === id) {
                    // Создаем копию поля с обновленными брендами
                    const updatedField = {
                        ...field,
                        country: country
                    };
                    return updatedField;
                }
                // Возвращаем неизмененные поля
                return field;
            });
        });
    }
    const UpdateField = async () => {
        for (const field of fields) {
            let totalPercent = 0;
            for (const brand of field.brands) {
                if (brand.percent !== null && brand.brand_status) {
                    totalPercent += +brand.percent;
                }
            }
            if (totalPercent > 100) {
                handleClick()
                setSnackMessage("Сумма процентов в поле превышает 100%");
                setSnackType("error");
                return;
            }
        }
        try {
            const { data } = await axios.patch(`https://www.leadhubcrm.pro:8081/api/flows/${rowId}`, {
                fields: fields
            },
                {
                    headers: {
                        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                    },
                })
            if (data) {
                const flows = await axios.get('https://www.leadhubcrm.pro:8081/api/flows', {
                    headers: {
                        'Authorization': 'Bearer ' + secureLocalStorage.getItem('token')
                    }
                })
                if (flows) {
                    setFlows(flows.data.flows)
                    setFilteredFlows(flows.data.flows)
                    handleClick()
                    setSnackMessage("Поток изменён успешно!");
                    setSnackType("success");
                    handleCloseModal()
                }
            }
        } catch (e) {
            handleClick()
            setSnackMessage(e.response.data.message);
            setSnackType("error");
        }
    }
    return (
        <>
            <DataGrid
                rows={filteredFlows}
                autoHeight={true}
                columns={columns}
                getRowId={(row) => row.id}
                initialState={{
                    pagination: {

                        paginationModel: {
                            pageSize:100,
                        },
                    },
                }}
                sx={{
                    backgroundColor: '#171E29',
                    width: '100%',
                    minWidth: '400px',
                    color: "rgba(231, 227, 252, 0.87)",
                    fontSize: "12px",
                    border: 'none',
                    fontFamily: "'Montserrat',sans-serif",
                    height: '75vh',
                    '&.MuiDataGrid-root': { height: '75vh' },
                    "& .MuiDataGrid-virtualScroller ": {

                        overflowY: 'scroll !important'
                    },
                    '& .MuiDataGrid-cell': {
                        borderBottom: "1px solid rgb(73, 72, 80)"
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        borderBottom: "1px solid rgb(73, 72, 80)"
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    },
                    '& .MuiPaginationItem-root': {
                        fontFamily: "'Montserrat',sans-serif",
                    },
                    '& .MuiButtonBase-root': {
                        color: '#fff'
                    },

                }}
                slots={{
                    pagination: CustomPagination,
                    noRowsOverlay: CustomNoRowsOverlay,
                    toolbar: CustomToolbar,
                    baseCheckbox: (props) => (
                        <Checkbox {...props} sx={{ color: "#688CF2" }} />
                    )
                }}
                pageSizeOptions={[100]}
                disableRowSelectionOnClick
                slotProps={{
                    toolbar: {
                        search: search,
                        setSearch: setSearch,
                        t: t,
                        handleOpenAddModal: handleOpenAddModal
                    }
                }}
            />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={handleCloseModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}

                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openModal}>
                    <Box sx={style}>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'start', justifyContent: 'start', gap: '20px', flexDirection: 'column' }}>
                            <h3 style={{ width: '100%', textAlign: 'center' }}>РЕДАКТИРОВАНИЕ ПОТОКА #{affId}</h3>
                            <span style={{ width: '100%', minHeight: '600px', maxHeight: "800px", overflowY: 'auto' }}>
                                {
                                    fields.length > 0 && (fields.map((el, index) =>
                                        <FlowsFields
                                            brands={brands}
                                            updateBrandInField={updateBrandInField}
                                            DelBrand={DelBrand}
                                            AddBrand={AddBrand}
                                            DelField={DelField}
                                            key={index}
                                            el={el}
                                            updateCountry={updateCountry}
                                            countries={countries}
                                            UpdateField={UpdateField}
                                        />))
                                }
                                <AddCircleRoundedIcon onClick={AddField} sx={{ cursor: 'pointer', width: '30px', height: '30px',marginTop:"50px" }} />
                            </span>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                                
                                <button onClick={UpdateField} style={{ border: 'none', backgroundColor: '#04948c', color: '#fff', padding: '10px', fontFamily: "'Montserrat' , sans-serif", borderRadius: "4px", fontWeight: '700', cursor: 'pointer' }}>{t('AdminEdit')}</button>
                            </div>
                            <CancelRoundedIcon
                                onClick={() => handleCloseModal()}
                                sx={{
                                    position: 'absolute',
                                    top: '45px',
                                    width: '30px',
                                    height: '30px',
                                    right: '35px',
                                    fill: '#fff',
                                    padding: '0',
                                    borderRadius: '50%',
                                    cursor: 'pointer'
                                }}
                            />
                        </div>

                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openAddModal}
                onClose={handleCloseAddModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}

                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openAddModal}>
                    <Box sx={style}>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'start', justifyContent: 'start', gap: '20px', flexDirection: 'column' }}>
                            <h3 style={{ width: '100%', textAlign: 'center' }}>ДОБАВЛЕНИЕ ПОТОКА</h3>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <label>Аффилиат</label>
                                <Select options={affiliates} onChange={(e) => { setAffiliate_id(e.value) }} styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        height: '50px',
                                        backgroundColor: 'rgb(18,26,33)',
                                        overflow: 'hidden',
                                        color: 'rgba(231, 227, 252, 0.87)'
                                    }),
                                    singleValue: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontWeight: '500',
                                        color: 'rgba(231, 227, 252, 0.87)'
                                    }),
                                    option: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: 'rgb(18,26,33)',
                                        color: 'rgba(231, 227, 252, 0.87)'
                                    }),
                                    input: (baseStyles, state) => ({
                                        ...baseStyles,
                                        color: 'rgba(231, 227, 252, 0.87)'
                                    }),
                                    placeholder: (baseStyles, state) => ({
                                        ...baseStyles,
                                        color: 'rgba(231, 227, 252, 0.87)'
                                    }),
                                    menuList: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: 'rgb(18,26,33)',
                                        color: 'rgba(231, 227, 252, 0.87)'

                                    }),
                                }} placeholder={'Аффилиат'}></Select>
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <label>Название потока</label>
                                <input value={title} onChange={(e)=> setTitle(e.target.value)} placeholder='Название' style={{ padding: '14.5px 10px', fontSize: '16px', fontFamily: '"Montserrat",sans-serif', background: '#121A21', border: "1px solid #B3b3b3", color: '#CCCAE0', borderRadius: '4px' }} />
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <label>Описание</label>
                                <input value={description} onChange={(e)=> setDescription(e.target.value)} placeholder='Описание' style={{ padding: '14.5px 10px', fontSize: '16px', fontFamily: '"Montserrat",sans-serif', background: '#121A21', border: "1px solid #B3b3b3", color: '#CCCAE0', borderRadius: '4px' }} />
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <label>EndPoint</label>
                                <input value={EndPoint} onChange={(e)=> setEndPoint(e.target.value)} placeholder='EndPoint' style={{ padding: '14.5px 10px', fontSize: '16px', fontFamily: '"Montserrat",sans-serif', background: '#121A21', border: "1px solid #B3b3b3", color: '#CCCAE0', borderRadius: '4px' }} />
                            </div>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                                <button onClick={AddFlow} style={{ border: 'none', backgroundColor: '#04948c', color: '#fff', padding: '10px', fontFamily: "'Montserrat' , sans-serif", borderRadius: "4px", fontWeight: '700', cursor: 'pointer' }}>ДОБАВИТЬ</button>
                            </div>
                            <CancelRoundedIcon
                                onClick={() => handleCloseAddModal()}
                                sx={{
                                    position: 'absolute',
                                    top: '10px',
                                    width: '40px',
                                    height: '40px',
                                    right: '10px',
                                    fill: '#fff',
                                    padding: '0',
                                    borderRadius: '50%',
                                    cursor: 'pointer'
                                }}
                            />
                        </div>

                    </Box>
                </Fade>
            </Modal>
        </>

    )
}