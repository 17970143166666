import styles from './Statuses.module.scss'
import NavBar from '../../components/NavBar';
import { useTranslation } from 'react-i18next';
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded';
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import StatusesDataGrid from './StatusesDataGrid';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { Navigate } from 'react-router-dom';
export default function Statuses({handleClick,setSnackType,setSnackMessage,isSmall , setSmall}){
    const {t} = useTranslation()
    const [search , setSearch] = useState('')
    
    return(
        secureLocalStorage.getItem('user')?.RoleId==5?
        <Navigate to={'/affiliate'}/>
        :
        <div className={styles.tables}>
            <NavBar isSmall={isSmall} setSmall={setSmall}/>
            <div className={styles.content}  style={isSmall?{marginLeft:'7.5%'}:{marginLeft:'14.5%'}}>
                <div className={styles.topContent}>
                    <h1>Статусы</h1>

                    {/* <span className={styles.topContentButtons}>
                        <button className={styles.Export}><CloudDownloadRoundedIcon/> {t("ClientsExport")}</button>
                        <button className={styles.Import}><CloudUploadRoundedIcon/> {t("ClientsImport")}</button>
                        <Link to={'/client/add'} className={styles.AddClient}><AddRoundedIcon/>{t("ClientsAddClient")}</Link>
                    </span> */}
                </div>
                <div className={styles.DataGrid}  style={isSmall?{width:'88.5vw'}:{width:'80.5vw'}}>
                    <StatusesDataGrid t={t}
                    handleClick={handleClick} 
                    setSnackType={setSnackType} 
                    setSnackMessage={setSnackMessage}
                    setSearch={setSearch} search={search}/>
                </div>
            </div>
        </div>
    )
}