import styles from './Desks.module.scss'
import NavBar from '../../components/NavBar';

import secureLocalStorage from 'react-secure-storage';
import { Navigate } from 'react-router-dom';
export default function Desks(){
    return(
        secureLocalStorage.getItem('user')?.RoleId==5?
        <Navigate to={'/affiliate'}/>
        :
        <div className={styles.desks}>
            <NavBar/>
        </div>
    )
}