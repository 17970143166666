import {
    DataGrid,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useEffect, useState } from 'react';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Backdrop, Fade, Modal} from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Select from 'react-select';
import Swal from 'sweetalert2';

function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <Pagination
            color="primary"
            variant="outlined"
            shape="rounded"
            page={page + 1}
            count={pageCount}
            // @ts-expect-error
            renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
        />
    );
}
const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '60px',
    height: '100%',
    '& .ant-empty-img-1': {
        fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
        fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
        fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
        fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
        fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
        fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
}));
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '12px',
    display: 'flex',
    gap: '15px',
    backgroundColor: '#fff',
    width: "300px",
};
function CustomNoRowsOverlay() {
    return (
        <StyledGridOverlay>
            <svg
                style={{ flexShrink: 0 }}
                width="200"
                height="160"
                viewBox="0 0 184 152"
                aria-hidden
                focusable="false"
            >
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(24 31.67)">
                        <ellipse
                            className="ant-empty-img-5"
                            cx="67.797"
                            cy="106.89"
                            rx="67.797"
                            ry="12.668"
                        />
                        <path
                            className="ant-empty-img-1"
                            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                            className="ant-empty-img-2"
                            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                            className="ant-empty-img-3"
                            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                    </g>
                    <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                    />
                    <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                    </g>
                </g>
            </svg>
            <Box sx={{ mt: 1 }}>Empty set</Box>
        </StyledGridOverlay>
    );
}
export default function UsersDataGrid({brands, roles,handleClick,setSnackType,setSnackMessage,setGridBrands,setBrands,t }) {
    const [currentRow, setCurrentRow] = useState()
    const [openModal, setOpenModal] = useState(false);
    const [role ,setRole] = useState()
    const [brand ,setBrand] = useState()
    const [desks ,setDesks] = useState()
    const [desk ,setDesk] = useState()
    const [login ,setLogin] = useState()
    const [users,setUsers] = useState([])
    const handleOpenModal = (row) => {
        setOpenModal(true)
        setCurrentRow(row)
    };
    const handleCloseModal = () => {
        setOpenModal(false);
        setCurrentRow(null)
    };
    useEffect(()=>{
        if(currentRow){
            setLogin(currentRow.Login)
            setRole({value: currentRow.RoleId , label:currentRow.Role})
            setBrand({value: currentRow.BrandId , label:currentRow.Brand})
            setDesk({value: currentRow.DeskId , label:currentRow.Desk})
        }
        if(!currentRow){
            setLogin('')
            setRole()
            setBrand()
            setDesk()
        }
    },[currentRow])
    useEffect(()=>{
        const fetchUsers = async ()=>{
            try{
                const {data} = await axios.get('https://www.leadhubcrm.pro:8081/api/users',{
                    headers: {
                      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                    },
                  })
                  if(data){
                    setUsers(data)
                  }
            }catch(e){
                handleClick();
                setSnackMessage(e.response.data.message);
                setSnackType("error"); 
            }
        }
        fetchUsers()
    },[])
    const columns = [
        {
            field: 'Login',
            headerName: 'Login',
            width: 210,
            editable: false,
            renderCell: (params) => {
                const currentRow = params.row;

                return (
                    <Link to={`/employee/${currentRow.Id}`} style={{fontWeight: '500', cursor: 'pointer',textDecoration:'none' , color:'#10706B' }} variant="outlined" color="secondary" size="small" >{currentRow.Login}</Link>
                );
            },
        },
        {
            field: 'Role',
            headerName: t('AdminRole'),
            width: 150,
            editable: false,
        },
        {
            field: 'Brand',
            headerName: t('AdminBrand'),
            width: 210,
            editable: false,
        },
        {
            field: 'Desk',
            headerName: t('AdminDesk'),
            width: 160,
            editable: false,
        },
        {
            field: 'Clients',
            headerName: t('AdminСlients'),
            width: 160,
            editable: false,
        },
        {
            field: 'LastAuthDate',
            headerName: t('AdminLastAuthDate'),
            width: 260,
            editable: false,
            renderCell: (params) => {
                const currentRow = params.row;

                return (
                    currentRow.LastAuthDate && currentRow.LastAuthIp?
                    <span style={{fontWeight: '500'}}>{moment(currentRow?.LastAuthDate).local().format("YYYY-MM-DD HH:mm:ss")} ({currentRow?.LastAuthIp})</span>:''
                );
            },
        },
        {
            field: 'action',
            headerName: '',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 210,
            editable: false,
            type:'number',
            renderCell: (params) => {
                const currentRow = params.row;

                return (
                    <div style={{display:'flex', gap:'10px'}}>
                    <button onClick={() => handleOpenModal(currentRow)} style={{ border: 'none', backgroundColor: '#04948c', color: '#fff', padding: '10px', fontFamily: "'Montserrat' , sans-serif", borderRadius: "4px", fontWeight: '700', cursor: 'pointer' }} variant="outlined" color="secondary" size="small" >{t('AdminEdit')}</button>
                    <button onClick={() => DeleteUser(currentRow?.Id)} style={{ border: 'none', backgroundColor: '#ee2e31', color: '#fff', padding: '10px', fontFamily: "'Montserrat' , sans-serif", borderRadius: "4px", fontWeight: '700', cursor: 'pointer' }} variant="outlined" color="secondary" size="small" >{t('AdminDelete')}</button>
                    </div>
                );
            },
        },
    ];
    const Edit = async () =>{
        if(!login){
            handleClick()
            setSnackType('error')
            setSnackMessage(t('AdminAddUserSnackBarLoginError'))
            return
        }
        if(!desk){
            handleClick()
            setSnackType('error')
            setSnackMessage(t('AdminAddUserSnackBarDeskError'))
            return
        }
        try{
            const {data} = await axios.patch(`https://www.leadhubcrm.pro:8081/api/users/${currentRow?.Id}`,{
                Login:login,
                RoleId: role?.value,
                BrandId: brand?.value,
                DeskId: desk?.value,
                user_id : secureLocalStorage.getItem('user')?.Id,
              },
              {
                headers: {
                  Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                },
              })
              if(data){
                handleCloseModal()
                handleClick()
                setSnackType('success')
                setSnackMessage(t('AdminEditUserSnackBarSuccess'))
                const users = await axios.get('https://www.leadhubcrm.pro:8081/api/users', {
                    headers: {
                        'Authorization': 'Bearer ' + secureLocalStorage.getItem('token')
                    }
                })
                if (users) {
                    setUsers(users.data)
                }
              }
        }catch(e){
            handleClick();
            setSnackMessage(e.response.data.message);
            setSnackType("error");
        }
    }
    useEffect(() => {
        if (brand) {
            const fetchBrand = async () => {
                try {
                    const { data } = await axios.get(`https://www.leadhubcrm.pro:8081/api/desks/brand/${brand.value}`, {
                        headers: {
                            'Authorization': 'Bearer ' + secureLocalStorage.getItem('token')
                        }
                    })
                    if (data) {
                        const arr = []
                        data.forEach(el => {
                            arr.push({ value: el.Id, label: el.Title })
                        })
                        setDesks(arr)
                    }
                } catch (e) {
                    console.log(e)
                }
            }
            fetchBrand()
        }
    }, [brand])
    const DeleteUser = async (id) => {
        Swal.fire({
            background: '#fff',
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            color: '#000',
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: 'rgba(255, 48, 48, 0.16)',
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
            buttonsStyling: false,
            customClass: {
                title: '',
                text: 'text',
                cancelButton: 'Custom_Cancel_LightMode',
                confirmButton: 'Custom_Confirm_LightMode',
            }

        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`https://www.leadhubcrm.pro:8081/api/users/${id}`,{
                        data:{user_id:secureLocalStorage.getItem('user').Id},
                        headers: {
                            Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                          },
                      })
                    const users = await axios.get('https://www.leadhubcrm.pro:8081/api/users', {
                        headers: {
                            'Authorization': 'Bearer ' + secureLocalStorage.getItem('token')
                        }
                    })
                    if (users) {
                        setUsers(users.data)
                    }
                    Swal.fire({
                        title: "Deleted!",
                        text: "Agent has been deleted.",
                        icon: "success",
                        color: '#000',
                        background: '#fff',
                        buttonsStyling: false,
                        customClass: {
                            confirmButton: 'Custom_Confirm_LightMode',
                        }
            
                    });
                } catch (e) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        color: '#000',
                        background: '#fff',
                        buttonsStyling: false,
                        customClass: {
                            confirmButton: 'Custom_Confirm_LightMode',
                        }
            
                    });
                }
            }
        });
    }
    return (
        <>
            <DataGrid
                rows={users}
                autoHeight={true}

                columns={columns}
                getRowId={(row) => row.Id}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 20,
                        },
                    },
                }}
                sx={{
                    color:'#fff',
                    width: '100%',
                    minWidth:'400px',
                    fontFamily: "'Montserrat',sans-serif",
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    },
                    '& .MuiPaginationItem-root': {
                        fontFamily: "'Montserrat',sans-serif",
                    },
                }}
                slots={{
                    pagination: CustomPagination,
                    noRowsOverlay: CustomNoRowsOverlay
                }}
                pageSizeOptions={[20]}
                checkboxSelection
                disableRowSelectionOnClick
            />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={handleCloseModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}

                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openModal}>
                    <Box sx={style}>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'start', justifyContent: 'start', gap: '20px', flexDirection: 'column' }}>
                            <h3 style={{ width: '100%', textAlign: 'center' }}>{t('AdminEditUser')}</h3>
                            <div style={{ width: '100%' }}>
                                <label style={{ marginTop: '0', fontWeight: '500' }}>{t('AdminUserLogin')}</label>
                                <input onChange={e=>setLogin(e.target.value)} style={{ width: 'calc(100% - 14px)', paddingLeft: '10px', fontFamily: '"Montserrat",sans-serif', height: "50px", borderRadius: '4px', border: '1px solid lightgrey', fontSize: '16px', fontWeight: '500' }} value={login} placeholder={t('AdminUserLoginEnter')} />
                            </div>
                            <div style={{ width: '100%' }}>
                                <label style={{marginBottom:'4px',fontWeight:'500'}}>{t('AdminRole')}</label>
                                <Select value={role} options={roles} onChange={(e)=>setRole({value:e.value , label:e.label})} styles={{
                                    control:(baseStyles,state)=>({
                                        ...baseStyles,
                                        height:'50px'
                                    }),
                                    singleValue:(baseStyles,state)=>({
                                        ...baseStyles,
                                        fontWeight:'500'
                                    })}} placeholder={t('AdminRoleSelect')}></Select>
                            </div>
                            <div style={{ width: '100%' }}>
                                <label style={{marginBottom:'4px',fontWeight:'500'}}>{t('AdminBrand')}</label>
                                <Select value={brand} options={brands} onChange={(e)=>{setBrand({value:e.value , label:e.label});setDesk(null)}} styles={{
                                    control:(baseStyles,state)=>({
                                        ...baseStyles,
                                        height:'50px'
                                    }),
                                    singleValue:(baseStyles,state)=>({
                                        ...baseStyles,
                                        fontWeight:'500'
                                    })}} placeholder={t('AdminBrandSelect')}></Select>
                            </div>
                            <div style={{ width: '100%' }}>
                                <label style={{marginBottom:'4px',fontWeight:'500'}}>{t('AdminDesk')}</label>
                                <Select styles={{
                                    control:(baseStyles,state)=>({
                                        ...baseStyles,
                                        height:'50px'
                                    }),
                                    singleValue:(baseStyles,state)=>({
                                        ...baseStyles,
                                        fontWeight:'500'
                                    })}} options={desks} value={desk} onChange={(e)=>setDesk({value : e.value ,label:e.label})} placeholder={t('AdminDeskSelect')}></Select>
                            </div>

                            <div style={{ width: '100%', textAlign: 'right' }}>
                                <button onClick={Edit} style={{ border: 'none', backgroundColor: '#04948c', color: '#fff', padding: '10px', fontFamily: "'Montserrat' , sans-serif", borderRadius: "4px", fontWeight: '700', cursor: 'pointer' }}>{t('AdminEdit')}</button>
                            </div>
                            <CancelRoundedIcon
                                onClick={() => handleCloseModal()}
                                sx={{
                                    position: 'absolute',

                                    top: '10px',
                                    width: '40px',
                                    height: '40px',
                                    right: '10px',
                                    fill: '#000',
                                    padding: '0',
                                    borderRadius: '50%',
                                    cursor: 'pointer'
                                }}
                            />
                        </div>

                    </Box>
                </Fade>
            </Modal>
        </>

    )
}