import { useState } from 'react'
import styles from './Admin.module.scss'

export default function AddBrand ({CreateBrand,t}){
    const [title , setTitle] = useState('')
    const [platformName , setPlatformName] = useState('')
    const [platformDomain , setPlatformDomain] = useState('')

    return(
        <div className={styles.addBrand}>
        <h3>{t('AdminAddBrand')}</h3>
            <div className={styles.addContent}>
                <span>
                    <div className={styles.input}>
                        <label>{t('AdminBrandName')}</label>
                        <input onChange={e=>setTitle(e.target.value)} placeholder={t('AdminBrandName')}/>
                    </div>
                    <div className={styles.input}>
                        <label style={{marginTop:'10px'}}>{t('AdminPlatformName')}</label>
                        <input onChange={e=>setPlatformName(e.target.value)} placeholder={t('AdminPlatformName')}/>
                    </div>
                    <div className={styles.input}>
                        <label style={{marginTop:'10px'}}>{t('AdminPlatformDomain')}</label>
                        <input onChange={e=>setPlatformDomain(e.target.value)} placeholder={t('AdminPlatformDomain')}/>
                    </div>
                </span>    
                    <div className={styles.createButton}>
                        <button onClick={()=>CreateBrand(title,platformName,platformDomain)} className={styles.create}>{t('AdminCreate')}</button>
                    </div>
                </div>
        </div>
    )
}