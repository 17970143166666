import styles from './AddClient.module.scss'
import NavBar from '../../components/NavBar';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Countries } from '../../countries';
import { useState } from 'react';
import Select from 'react-select';

import secureLocalStorage from 'react-secure-storage';
import { Navigate } from 'react-router-dom';
export default function AddClient({isSmall , setSmall}){
    const {t} = useTranslation()
    const desks = []
    const [desk,setDesk]=useState()
    return(
        secureLocalStorage.getItem('user')?.RoleId==5?
        <Navigate to={'/affiliate'}/>
        :
        <div className={styles.tables}>
            <NavBar isSmall={isSmall} setSmall={setSmall}/>
            <div className={styles.content}  style={isSmall?{marginLeft:'7.5%'}:{marginLeft:'19.5%'}}>
                <div className={styles.topContent}>
                    <h1>{t("ClientsAddClient")}</h1>

                    <span className={styles.topContentButtons}>

                        <Link to={'/client/add'} className={styles.AddClient}><AddRoundedIcon/>{t("AddClientSave")}</Link>
                    </span>

                </div>
                <div className={styles.fields}>
                            <div className={styles.clientInfo}>
                                <h2>{t("AddClientGeneral")}</h2>
                                <div className={styles.info}>
                                    <label>{t("AddClientLastname")}</label>
                                    <input placeholder={t("AddClientLastname")}/>
                                </div>
                                <div className={styles.info}>
                                    <label>{t("AddClientFirstname")}</label>
                                    <input placeholder={t("AddClientFirstname")}/>
                                </div>
                                <div className={styles.info}>
                                    <label>{t("AddClientMiddlename")}</label>
                                    <input placeholder={t("AddClientMiddlename")}/>
                                </div>
                                <div className={styles.info}>
                                    <label>{t('AdminDesk')}</label>
                                    <Select options={desks} value={desk}  styles={{
                                    control:(baseStyles,state)=>({
                                        ...baseStyles,
                                        width:'400px',
                                        marginTop:'4px'
                                    }),
                                    singleValue:(baseStyles,state)=>({
                                        ...baseStyles,
                                        fontWeight:'500'
                                    })}} onChange={(e)=>setDesk({value : e.value ,label:e.label})} className={styles.deskSelect} placeholder={t('AdminDeskSelect')}></Select>
                                </div>
                                <div className={styles.info}>
                                    <label>{t('ClientsGridResponsible')}</label>
                                    <Select options={desks} value={desk}  styles={{
                                    control:(baseStyles,state)=>({
                                        ...baseStyles,
                                        width:'400px',
                                        marginTop:'4px'
                                    }),
                                    singleValue:(baseStyles,state)=>({
                                        ...baseStyles,
                                        fontWeight:'500'
                                    })}} onChange={(e)=>setDesk({value : e.value ,label:e.label})} className={styles.deskSelect} placeholder={t('ClientsGridResponsible')}></Select>
                                </div>
                                <div className={styles.info}>
                                    <label>{t('ClientsGridStatus')}</label>
                                    <Select options={desks} value={desk}  styles={{
                                    control:(baseStyles,state)=>({
                                        ...baseStyles,
                                        width:'400px',
                                        marginTop:'4px'
                                    }),
                                    singleValue:(baseStyles,state)=>({
                                        ...baseStyles,
                                        fontWeight:'500'
                                    })}} onChange={(e)=>setDesk({value : e.value ,label:e.label})} className={styles.deskSelect} placeholder={t('ClientsGridStatus')}></Select>
                                </div>
                                <div className={styles.info}>
                                    <label>{t("AddClientAffiliateid")}</label>
                                    <input placeholder={t("AddClientAffiliateid")}/>
                                </div>
                            </div>
                            <div></div>
                </div>
            </div>
        </div>
    )
}