import styles from './MyProjects.module.scss'
import NavBar from '../../components/NavBar';
import secureLocalStorage from 'react-secure-storage';
import { Navigate } from 'react-router-dom';

export default function MyProjects(){
    return(
        secureLocalStorage.getItem('user')?.RoleId==5?
        <Navigate to={'/affiliate'}/>
        :
        <div className={styles.myprojects}>
            <NavBar/>
        </div>
    )
}