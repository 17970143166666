import styles from './Tables.module.scss'
import NavBar from '../../components/NavBar';

import secureLocalStorage from 'react-secure-storage';
import { Navigate } from 'react-router-dom';
export default function Tables(){
    return(
        secureLocalStorage.getItem('user')?.RoleId==5?
        <Navigate to={'/affiliate'}/>
        :
        <div className={styles.tables}>
            <NavBar/>
            <div className={styles.content}>
                
            </div>
        </div>
    )
}